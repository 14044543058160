import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Home as HomeIcon,
  Info as InfoIcon,
  Settings as SettingsIcon
} from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import { IAppVersionContextData, useAppVersionContext } from '../../providersAndContexts/appVersion';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the overall footer
const StyledBoxForFooterArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 0,
  border: 'none',
  stroke: 'none',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

// a styled Box (equivalent to a <div>), providing an area to display footer icons
const StyledBoxForFooterIconArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  padding: '0.0rem 0.25rem 0.0rem 0.25rem',
  display: 'flex',
  justifyContent: 'space-evenly',
  background: theme.appFooter.iconArea.background,
  color: theme.appFooter.iconArea.color,
  border: 'none',
}));

// a styled Box (equivalent to a <div>), providing an area to display footer information
const StyledBoxForFooterInfoDisplayArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  padding: '0.25rem 1.0rem',
  display: 'flex',
  justifyContent: 'space-between',
  background: theme.appFooter.infoDisplayArea.background,
  color: theme.appFooter.infoDisplayArea.color,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

// a styled Box (equivalent to a <div>) to hold a footer information item
const StyledBoxFooterInfoItem = styled(Box)({
  flexBasis: '33%',
  textAlign: 'center',
});

// a styled Typography element for standardized display of footer information items
const StyledTypographyForFooterInfoItem = styled((props) => (
  <Typography
    variant='body2'
    {...props}
  />
))(({ theme }) => ({
}));

// a style IconButton to be used for icon buttons within the footer area
const StyledIconButton = styled((props) => (
  <IconButton
    size='small'
    {...props}
  />
))(({ theme }) => ({
  color: theme.appFooter.iconArea.color,
  '&:hover': {
    color: theme.appFooter.iconArea.hover.color,
    transform: 'scale(1.1)',
  }
}));


export const AppFooter: React.FC = () => {

  const navigate = useNavigate();

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c AppFooter. currentUser: \n${JSON.stringify(currentUser)}`, 'background: #600; color: #fff');

  // use a custom hook to get the App Version from the AppVersionProvider
  const appVersionContextData: IAppVersionContextData = useAppVersionContext();
  displayConsoleLogs && console.log(`%c AppFooter. appVersion: \n${appVersionContextData.state.appVersion}`, 'background: #600; color: #fff');

  // will hold the latest app version obtained from state
  const [appVersion] = useState<string>(appVersionContextData.state.appVersion);

  // will hold the current year (for the copyright statement)
  const [currentYear] = useState<number>(new Date().getFullYear());

  // set up a string to identify the build mode (if REACT_APP_NODE_ENV is available, use it; otherwise, use NODE_ENV)
  // Note: We could merely embed process.env.REACT_APP_NODE_ENV in the code below, but we specifically identify the string for 
  //       self-documentation.
  // const buildModeString: string = process.env.NODE_ENV;
  const buildModeString: string = process.env.REACT_APP_NODE_ENV ?? process.env.NODE_ENV;

  return (
    <>
      <StyledBoxForFooterArea>

        <StyledBoxForFooterIconArea>
          <StyledIconButton aria-label="home" onClick={() => navigate('/')} >
            <HomeIcon />
          </StyledIconButton>
          <StyledIconButton aria-label="settings" onClick={() => navigate('/settings')} >
            <SettingsIcon />
          </StyledIconButton>
          <StyledIconButton aria-label="about" onClick={() => navigate('/about')} >
            <InfoIcon />
          </StyledIconButton>
        </StyledBoxForFooterIconArea>

        <StyledBoxForFooterInfoDisplayArea>
          <StyledBoxFooterInfoItem sx={{ textAlign: { sm: 'left' } }}> {/* At Small screen size or greater, align contents to the left */}
            {
              currentUser?.userSettings &&
              <StyledTypographyForFooterInfoItem>
                {`${currentUser.userSettings.firstName} ${currentUser.userSettings.lastName} (${currentUser.userSettings.email})`}
              </StyledTypographyForFooterInfoItem>
            }
          </StyledBoxFooterInfoItem>
          <StyledBoxFooterInfoItem>
            <StyledTypographyForFooterInfoItem>
              Copyright &copy; MyDigiBrain 2021-{`${currentYear} (${buildModeString})`}
            </StyledTypographyForFooterInfoItem>
          </StyledBoxFooterInfoItem>
          <StyledBoxFooterInfoItem sx={{ textAlign: { sm: 'right' } }}> {/* At Small screen size or greater, align contents to the right */}
            {
              appVersion &&
              <StyledTypographyForFooterInfoItem>
                {`Version: ${appVersion}`}
              </StyledTypographyForFooterInfoItem>
            }
          </StyledBoxFooterInfoItem>
        </StyledBoxForFooterInfoDisplayArea>

      </StyledBoxForFooterArea>
    </>
  );
}
