import _ from 'lodash';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { AppVersionContext } from '.';
import { IAppVersionContextData } from './IAppVersionContextData';

/**
 * @interface IAppVersionProviderProps declares any input properties required for this component.
 */
export interface IAppVersionProviderProps extends PropsWithChildren<unknown> {
}


/**
 * @provider AppVersionProvider A React Provider component that is based on the AppVersionContext and can be used to provide a 
 *   React component tree embedded in the provider with information for the application's version. 
 */
export const AppVersionProvider: FC<IAppVersionProviderProps> = (props: IAppVersionProviderProps) => {

  AppVersionProvider.displayName = "AppVersionProvider";

  // whether to display console logs (console.log statements)
  const displayConsoleLogs: boolean = false;

  // fetch the 'children' property from the input properties
  const { children } = props;

  // The current UI theme for the user
  const [appVersion, setAppVersion] = useState<string>('1.0.0');

  // a useEffect hook that is executed upon mounting of the component
  useEffect(() => {
    displayConsoleLogs && console.log(`%c AppVersionProvider, in useEffect upon mounting. Ready to get AppVersion from environment variable`, 'background: #600; color: #fff');

    const appVersionFromEnvironmentVariable: string | undefined = process.env.REACT_APP_VERSION;

    if (appVersionFromEnvironmentVariable) {
      setAppVersion(appVersionFromEnvironmentVariable);
      // setAppVersion('New App Version from within AppVersionProvider');
    }

    // setAppVersion('App Version from within AppVersionProvider');

  }, []);


  // Prepare the Context object with available information. If themePreparationComplete, provide all values. If not, 
  // provide undefined values.
  const appVersionContextData: IAppVersionContextData =
  {
    state: {
      appVersion: appVersion
    },
    actions: {
    }
  }


  // this provider forwards the value to the AppVersionContext.Provider
  return (
    <AppVersionContext.Provider value={appVersionContextData}>
      {children}
    </AppVersionContext.Provider>
  )
}
